import { defineStore } from 'pinia'
import { camelCase, startCase } from 'lodash-es'
import useHelpers from '~/composables/useHelpers'

import HomepageData = models.server.api.graphQuery.homepage.Data

interface State {
  data: HomepageData
}

export const useIntHomepageStore = defineStore('intHomepage', {
  state: (): State => ({
    data: {}
  }),

  actions: {
    async fetch() {
      const { getIntHomepageContent } = useInternationalApi()
      if (this.data && Object.keys(this.data).length) return

      const { data } = await getIntHomepageContent()
      this.data = data || {}
    }
  },

  getters: {
    banner: (state) => {
      const { getImgSrc, substrBeforeLast } = useHelpers()
      const banner = state.data?.banner
      return banner && Object.keys(banner).length
        ? {
            title: banner.title?.value,
            subtitle: banner.subtitle?.value,
            subtitleColour: banner.subtitleColour?.value,
            heroImage: {
              ...banner.heroImage,
              src: substrBeforeLast(getImgSrc(banner.heroImage?.src), '-')
            }
          }
        : undefined
    },
    popularCourseAreas: (state) => {
      const popularCourseAreas = state.data?.popularCourseAreas
      return popularCourseAreas && Object.keys(popularCourseAreas).length
        ? {
            title: popularCourseAreas.title?.value,
            buttonText: popularCourseAreas.button?.text
          }
        : undefined
    },
    popularCourseAreaList: (state) => {
      const popularCourseAreas = state.data?.popularCourseAreas
      return popularCourseAreas && Object.keys(popularCourseAreas).length
        ? popularCourseAreas.courseAreas?.targetItems?.map((x) => {
            return {
              title: x.title?.value,
              url: `international/course-areas/${x.url?.value}`,
              ...(x.icon?.value && {
                icon: startCase(camelCase(x.icon?.value)).replace(/ /g, '')
              })
            }
          })
        : undefined
    },
    courseCards: (state) => {
      const promotions = state.data?.promotions1
      return promotions && Object.keys(promotions).length
        ? {
            title: promotions.title?.value,
            subtitle: promotions.subtitle?.value,
            subtitleColour: promotions.subtitleColour?.value
          }
        : undefined
    },
    courseCardList: (state) => {
      const promotions = state.data?.promotions1
      return promotions && Object.keys(promotions).length
        ? promotions.promos?.targetItems?.map((x) => {
            return {
              title: x.title?.value,
              subtitle: x.subtitle?.value,
              image: {
                src: x.image?.src,
                alt: x.image?.alt
              },
              content: x.description?.value || '',
              themeColor: x.subtitleColour?.value,
              link: {
                to: x.button?.url
              }
            }
          })
        : undefined
    },
    promotions2: (state) => {
      const promotions = state.data?.promotions2
      return promotions && Object.keys(promotions).length
        ? {
            promotionsTitle: promotions.title?.value,
            promotionsSubtitle: promotions.subtitle?.value,
            promotionsSubtitleColour: promotions.subtitleColour?.value,
            promos: promotions.promos?.targetItems
          }
        : undefined
    },
    promotions3: (state) => {
      const promotions = state.data?.promotions3
      return promotions && Object.keys(promotions).length
        ? {
            promotionsTitle: promotions.title?.value,
            promotionsSubtitle: promotions.subtitle?.value,
            promotionsSubtitleColour: promotions.subtitleColour?.value,
            promos: promotions.promos?.targetItems
          }
        : undefined
    },
    headline: (state) => {
      const headline = state.data?.headline
      return headline && Object.keys(headline).length
        ? {
            title: headline.title?.value,
            description: headline.description?.value || ''
          }
        : undefined
    },
    video: (state) => {
      const video = state.data?.video
      return video && Object.keys(video).length
        ? {
            title: video.title?.value,
            subTitle: video.subTitle?.value,
            description: video.description?.value || '',
            url: video.url?.value || '',
            thumbnailImage: video.thumbnailImage?.src
          }
        : undefined
    },
    testimonials: (state) => {
      const testimonials = state.data?.testimonials
      return testimonials && Object.keys(testimonials).length
        ? {
            title: testimonials.title?.value
          }
        : undefined
    },
    testimonialList: (state) => {
      const testimonials = state.data?.testimonials
      return testimonials && Object.keys(testimonials).length
        ? testimonials.items?.targetItems?.map((item) => {
            return {
              title: item.title?.value,
              subtitle: item.subtitle?.value,
              content: item.description?.value || '',
              image: item.image
            }
          })
        : undefined
    },
    cta: (state) => {
      const cta = state.data?.cta
      return cta && Object.keys(cta).length
        ? {
            title: cta.title?.value,
            url: cta.button?.url,
            description: cta.description?.value || '',
            linkType: cta.button?.linkType,
            ctaLabel: cta.button?.text,
            target: cta.button?.target
          }
        : undefined
    }
  }
})
